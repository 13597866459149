<template>
  <footer class="text-sm pt-8">
    <BasicScrollToTop></BasicScrollToTop>
    <div >
      <div class="container mx-auto py-4 lg:py-5" v-if="!isReservation">
        <div class="lg:text-left lg:grid grid-cols-12 lg:pb-7 gap-12 font-bold">
          <div class="col-span-5 pb-7 lg:pb-0">
            <div class="text-center lg:pb-7">Wir akzeptieren im Onlineshop folgende Zahlarten</div>
            <div class="border-t border-b lg:border-0">
              <div class="grid grid-cols-3 lg:flex lg:justify-between items-center gap-4 gap-y-6 p-4 lg:p-0">
                <div class="flex justify-center" v-for="entry in paymentprovider.service" :key="entry.image">
                  <nuxt-img densities="x1" loading="lazy" :src="entry.image" provider="cloudflare" :title="entry.title" :alt="entry.alt"
                    style="min-height: 20px; max-height: 40px" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-3 pb-7 lg:pb-0">
            <div class="text-center lg:pb-7">Unsere Versandpartner</div>
            <div class="flex justify-center items-center gap-4">
              <img
                class="h-6"
                alt="DPD"
                data-src="https://cdn.expert.de/24/63/cf/3833c72c0130ef578cb103096c99f85893/DPD_Logo_90x90_90-0.jpeg"
                src="https://cdn.expert.de/24/63/cf/3833c72c0130ef578cb103096c99f85893/DPD_Logo_90x90_90-0.jpeg"
              />
              <img
                class="h-9"
                alt="GEL"
                data-src="https://cdn.expert.de/d1/a1/f7/402e44aab08d63e970c2349a91465ffbb5/GEL-Logo_90x90_90-0.jpeg"
                src="https://cdn.expert.de/d1/a1/f7/402e44aab08d63e970c2349a91465ffbb5/GEL-Logo_90x90_90-0.jpeg"
              />
            </div>
          </div>
          <div class="col-span-4">
            <div class="text-center lg:pb-7">Sicher einkaufen bei expert</div>
            <div class="flex justify-center items-center gap-4">
              <a
                :title="entry.title"
                :href="entry.href"
                v-for="entry in shoprating.service"
                :key="entry.image"
              >
                <nuxt-img
                  loading="lazy"
                  width="50"
                  height="44"
                  fit="contain"
                  provider="cloudflare"
                  :src="entry.image"
                  :alt="entry.alt"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mb-4 lg:hidden">
        <span v-for="(link, idx) in hardlinksMobile.links">
          <template v-if="idx % 4 != 0">&nbsp;|&nbsp;</template>
          <template v-else-if="idx > 0"><br/></template>
          <a class="text-primary" :title="link.title" :key="link.title" :href="link.href" v-text="link.title" />
        </span>
      </div>
      <div class="text-center p-4 lg:hidden border-t">
        <div class="text-center lg:pb-7 font-bold">Folgen Sie uns</div>
        <div :class="'grid-cols-' + socialMedia.service.length" class="grid lg:flex lg:justify-between items-center gap-4 gap-y-6 p-4 lg:p-0">
          <a class="flex justify-center" v-for="entry in socialMedia.service" :title="entry.title" :href="entry.href" :key="entry.image" target="_blank" rel="noopener">
            <nuxt-img loading="lazy" width="40" fit="contain" provider="cloudflare" :src="entry.image" :alt="entry.alt" />
          </a>
        </div>
      </div>
      <div class="hidden lg:block bg-secondary solid border-t border-secondary">
        <div class="lg:container lg:mx-auto py-5 flex text-paragraph font-bold justify-between text-center">
          <div class="text-left flex items-center gap-2"><BasicIcon class="w-4" :name="'address-card-regular'" /> Experten-Beratung</div>
          <div class="flex items-center gap-2"><BasicIcon class="w-4" :name="'wrench-solid'" /> Reparatur-Service</div>
          <div class="flex items-center gap-2"><BasicIcon class="w-4" :name="'gear-solid'" /> Montage-Service</div>
          <div class="flex items-center gap-2"><BasicIcon class="w-3" :name="'euro-sign-solid'" /> Finanzierungs-Service</div>
          <div class="flex items-center gap-2"><BasicIcon class="w-4" :name="'rotate-regular'" /> Altgeräte-Entsorgung</div>
          <div class="text-right flex items-center gap-2"><BasicIcon class="w-4" :name="'truck-solid'" /> Liefer-/Abholservice</div>
        </div>
      </div>
    </div>
    <div class="text-center lg:text-left p-4">
      <div class="container mx-auto lg:py-5 flex lg:justify-between lg:gap-8 justify-center">
        <div class="mb-4 w-72 lg:w-auto lg:text-left text-center">
          <p class="lg:mb-0 mb-4">
            Alle Preise in Euro und inkl. der gesetzlichen Mehrwertsteuer. ggf. zzgl.
            Versandkosten. Lieferung innerhalb Deutschlands.
          </p>
          <p>
            Änderungen und Irrtümer vorbehalten. Abbil<span class="lg:hidden">-</span>dungen ähnlich. Nur solange der
            Vorrat reicht.
          </p>
          <p class="font-bold mt-8">© expert e-Commerce GmbH</p>  
        </div>
        <div class="nav-item text-center lg:text-right lg:flex flex-col hidden">
          <a
            :title="link.title"
            v-for="link in hardlinks.links"
            :key="link.title"
            :href="link.href"
            v-text="link.title"
          />
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
import { inject } from "vue";
import { getExpNavigationTree } from "~/composables/ExpApi/expCache";
import { ProvideNames } from "~/composables/statics";
import { useCurrentStore } from "~/composables/states";
const props = defineProps({
  enableNewsletterTeaserModal: {
    type: Boolean,
    default: false,
  },
  isReservation: {
    type: Boolean,
    default: false
  },
  baseDir: {
    default: () => {
      return inject(ProvideNames.BASE_DIR);
    },
  },
});

const { pending, data: navigationTree } = await getExpNavigationTree("FOOTER");


const paymentprovider = {
  service: [
    {
      image: "/59/b1/e7/d5c2ef10b639c5fa5c7135def83b8ffd2d/paypal.png",
      alt: "PayPal",
      title: "PayPal",
    },
    {
      image: "/59/66/c1/5f9097f6f0a1870b1bc1aa65adbab43b50/AmericanExpress-New.png",
      alt: "AmericanExpress",
      title: "AmericanExpress",
    },
    {
      image: "/52/b5/f9/f9b6cfdc549077bf4ceaa072a07fe542fa/Visa.png",
      alt: "Visa",
      title: "Visa",
    },
    {
      image: "/6f/c6/7d/ceb8c0229fab5cb8a7f84b8015bc7260de/mastercard.png",
      alt: "mastercard",
      title: "mastercard",
    },
    {
      image: "/63/8d/a9/844f4b4d26f4c76fd92f68e6b8daac93c0/Klarna.png",
      alt: "Klarna",
      title: "Klarna",
    },
    {
      image: "/68/1a/97/f3eeb92221b695fb99a95694480b268159/Billie-logo.png",
      alt: "Billie",
      title: "Billie",
    },
  ],
};

const shoprating = {
  service: [
    {
      image: "/static/images/test/trustedShop.jpg",
      href:
        "https://www.trustedshops.de/bewertung/info_X6C1E9EE9E034D3276D30CB55A1778205.html",
      alt: "trusted shop",
      title: "",
    },
    {
      image: "/98/43/45/4873302d7ac7b399e54afc07be2bd60d8f/s1_idealo-partner_90-0.png",
      href: "https://www.idealo.de/preisvergleich/Shop/286835.html",
      alt: "unsere Bewertungen bei idealo.de",
      title: "unsere Bewertungen bei idealo.de",
    },
  ],
};

const socialMedia = {
  service: [
    {
      image: "/eb/fd/05/2146e9cc5a36bdffe8cfe7d326b87756e1/facebook.png",
      href: "https://de-de.facebook.com/expert.de",
      alt: "facebook",
      title: "facebook",
    },
    {
      image: "/63/02/70/3e894ae4e99c7f00f3dbd04fa67ce06b18/instagram.png",
      href: "https://www.instagram.com/expert_de/",
      alt: "instagram",
      title: "instagram",
    },
    {
      image: "/50/3f/ab/fc71c60fba70b279f1b93748bf83552e42/linkedin.png",
      href: "https://www.linkedin.com/company/expert-se",
      alt: "linkedin",
      title: "linkedin",
    },
    {
      image: "/94/f6/f0/13d13d1ab0aa8493fa620f99dd1a257bc9/xing.png",
      href: "https://www.xing.com/companies/expertse",
      alt: "xing",
      title: "xing",
    },
    {
      image: "/87/c2/d6/211532183113bb6cf090da56a1eafcd86a/youtube.png",
      href: "https://www.youtube.com/user/expertDeutschland",
      alt: "youtube",
      title: "youtube",
    },
  ],
};

const hardlinks = {
  links: [
    {
      title: "Impressum",
      href: "/Footer/Website/Impressum",
    },
    {
      title: "Informationen nach ElektroG",
      href: "/Footer/Website/Informationen-nach-ElektroG",
    },
    {
      title: "Datenschutzerklärung",
      href: "/Footer/Website/Datenschutzerklaerung",
    },
    {
      title: "Allgemeine Geschäftsbedingungen",
      href: "/Footer/Website/AGB-bei-Onlinekaeufen",
    },
  ],
};

const hardlinksMobile = {
  links: [
    {
      title: "Impressum",
      href: "/Footer/Website/Impressum",
    },
    {
      title: "Kontakt",
      href: "/Footer/Service/Kontakt",
    },
    {
      title: "Datenschutz",
      href: "/Footer/Website/Datenschutzerklaerung",
    },
    {
      title: "AGB",
      href: "/Footer/Website/AGB-bei-Onlinekaeufen",
    },
    {
      title: "Informationen nach ElektroG",
      href: "/Footer/Website/Informationen-nach-ElektroG",
    },
    {
      title: "FAQ",
      href: "https://www.expert.de/gevelsberg1/Footer/Service/Fragen-Antworten",
    },
  ],
};
</script>
