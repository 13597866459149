<template>
  <header class="header sticky-header p-4 lg:px-0 min-h-[7rem]">
    <div
      class="px-4 lg:px-0 lg:container lg:mx-auto flex flex-col lg:flex-row justify-between gap-4 lg:gap-12"
    >
      <div
        class="store-logo-container flex justify-center lg:block items-center h-18 lg:h-auto bg-white"
      >
        <NavigationStoreLogo />
      </div>
      <div class="lg:block lg:grow">
        <div
          class="text-sm lg:text-base lg:float-right grid grid-cols-2 lg:flex lg:flex-col gap-2"
        >
          <div class="flex gap-2 items-baseline lg:items-center">
            <BasicIcon class="w-4 text-primary" :name="'house-chimney-solid'" />
            <div>
              {{ useCurrentStore()?.value?.street }} -
              {{ useCurrentStore()?.value?.zip }} {{ useCurrentStore()?.value?.city }}
            </div>
          </div>
          <div class="storeOpening flex items-baseline lg:items-center gap-2" v-if="localStore?.openings">
            <BasicIcon class="w-4 text-primary" :name="'clock-nine-regular'" />
            {{
              (function () {
                const days = [
                  "MONDAY",
                  "TUESDAY",
                  "WEDNESDAY",
                  "THURSDAY",
                  "FRIDAY",
                  "SATURDAY",
                  "SUNDAY",
                ];
                let openings = localStore?.openings;
                let currentDate = new Date();
                let today = currentDate.getDay() - 1;
                let now = currentDate.getHours() * 60 + currentDate.getMinutes();
                let firstOpenToday = null;
                let nextOpenToday = 1441;
                let lastOpenToday = null;
                let notOnBreak = false;
                for (let i = 0; i < openings?.length; i++) {
                  let opening = openings[i];
                  if (
                    days.indexOf(opening.dayFrom) <= today &&
                    today <= days.indexOf(opening.dayTo)
                  ) {
                    if (firstOpenToday == null || firstOpenToday > opening.timeFrom)
                      firstOpenToday = opening.timeFrom;
                    if (nextOpenToday > opening.timeFrom && opening.timeTo > now)
                      nextOpenToday = opening.timeFrom;
                    if (lastOpenToday == null || lastOpenToday < opening.timeTo)
                      lastOpenToday = opening.timeTo;
                    if (opening.timeFrom <= now && now <= opening.timeTo)
                      notOnBreak = true;
                  }
                }
                if (firstOpenToday == null || lastOpenToday == null) {
                  return "Heute geschlossen!";
                }
                if (firstOpenToday <= now && now <= lastOpenToday) {
                  if (notOnBreak)
                    return "Heute bis " + getTimeString(lastOpenToday) + " Uhr geöffnet";
                  else return "Geöffnet ab " + getTimeString(nextOpenToday) + " Uhr";
                } else if (now < firstOpenToday)
                  return "Geöffnet ab " + getTimeString(firstOpenToday) + " Uhr";
                else return "Heute bereits geschlossen!";
              })()
            }}
          </div>
          <div
            class="storeOpening flex items-center gap-2"
            v-else-if="
              localStore?.openingHours !== undefined &&
              localStore?.openingHours !== null &&
              localStore.openingHours.length > 0
            "
          >
            <BasicIcon class="w-4 text-primary" :name="'clock-nine-regular'" />
            <div
              class="flex gap-1 lg:grow lg:grid"
              style="grid-template-columns: auto auto"
            >
              <template v-for="range in localStore.openingHours" :key="range">
                <div style="text-transform: capitalize">
                  {{ range.dayFrom }}.<template v-if="range.dayFrom !== range.dayTo">
                    -
                    {{ range.dayTo }}.</template
                  >
                </div>
                <div>{{ range.timeFrom }} - {{ range.timeTo }} Uhr</div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="gap-2 items-baseline hidden lg:flex">
        <BasicIcon class="w-4 text-primary" :name="'phone-solid'" />
        <div class="pb-2">
          <div class="text-lg font-bold">Kundenhotline</div>
          <div class="text-sm relative">
            0203 - 80481842
            <BasicIcon
              class="w-4 align-text-bottom text-paragraph-light inline-block"
              :name="'circle-info-light'"
              @click.prevent="itemOnDisplayHover = !itemOnDisplayHover"
              @mouseleave="$device.isDesktop ? (itemOnDisplayHover = false) : false"
              @mouseover="$device.isDesktop ? (itemOnDisplayHover = true) : false"
            />
            <div
              v-if="itemOnDisplayHover"
              class="hover-info border border-secondary z-20 w-[325px] bottom-auto top-full right-0"
            >
              (erreichbar Mo-Sa von 09:00 – 20:00 Uhr). Alle Preise in Euro und inkl. der
              gesetzlichen Mehrwertsteuer. ggf. zzgl. Versandkosten. Lieferung innerhalb
              Deutschlands. Änderungen und Irrtümer vorbehalten. Abbildungen ähnlich. Nur
              solange der Vorrat reicht. © expert e-Commerce GmbH
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
const today = new Date().getDay();
const openFrom = ref("So. geschlossen");
const openFrom2Morrow = ref("So. geschlossen");
const itemOnDisplayHover = ref(false);
const localStore = useCurrentStore()?.value;
const dayMapping = [
  { day: "mo", num: 1 },
  { day: "di", num: 2 },
  { day: "mi", num: 3 },
  { day: "do", num: 4 },
  { day: "fr", num: 5 },
  { day: "sa", num: 6 },
  { day: "so", num: 0 },
];

function getTimeString(timeInt) {
  var hours = Math.floor(timeInt / 60);
  hours = hours + "";
  while (hours.length < 2) hours = "0" + hours;
  var minutes = timeInt % 60;
  minutes = minutes + "";
  while (minutes.length < 2) minutes = "0" + minutes;
  return hours + ":" + minutes;
}

let opened = useCurrentStore()?.value?.openingHours?.find((open) =>
  dayMapping.find((dm) => dm.day == open.dayFrom && dm.num >= today && today != 0)
);
if (opened)
  openFrom.value =
    dayMapping
      .find((da) => da.num == today)
      ?.day.at(0)
      .toUpperCase() +
    dayMapping.find((da) => da.num == today)?.day.slice(1) +
    ". " +
    opened?.timeFrom +
    " - " +
    opened?.timeTo +
    " Uhr";

let opened2m = useCurrentStore()?.value?.openingHours?.find((open) =>
  dayMapping.find((dm) => dm.day == open.dayFrom && dm.num >= today + 1)
);
if (opened2m)
  openFrom2Morrow.value =
    dayMapping
      .find((da) => da.num == today + 1)
      ?.day.at(0)
      .toUpperCase() +
    dayMapping.find((da) => da.num == today + 1)?.day.slice(1) +
    ". " +
    opened2m?.timeFrom +
    " - " +
    opened2m?.timeTo +
    " Uhr";
</script>
<style>
.store-logo {
  min-height: 3em;
}
</style>